import { IconBrandFacebook, IconBrandInstagram, IconBrandWhatsapp, IconHelpHexagonFilled, IconMail } from '@tabler/icons-react';

export const footerItems = [
   {
      text: '+54 9 351 208 6545',
      icon: IconBrandWhatsapp,
      isContactItem: true,
      to: 'https://wa.me/+5493512086545?text=¡Hola!%20Buenos%20días,%20tengo%20una%20consulta...',
      target:'_blank'
   },
   {
      text: 'Administración',
      icon: IconMail,
      isContactItem: true,
      to: `mailto:administracion@forvet.org?subject=${encodeURIComponent('Consulta')}`,
      target:'_blank'
   },
   {
      text: 'Dirección Académica',
      icon: IconMail,
      isContactItem: true,
      to: `mailto:direccion_academica@forvet.org?subject=${encodeURIComponent('Consulta')}`,
      target:'_blank'
   },
   {
      text: 'Comercial',
      icon: IconMail,
      isContactItem: true,
      to: `mailto:ventas@forvet.org?subject=${encodeURIComponent('Consulta')}&body=${encodeURIComponent('Hola, me gustaría recibir información sobre su oferta académica.')}`,
      target:'_blank'
   },
   {
      text: 'Preguntas Frecuentes',
      icon: IconHelpHexagonFilled,
      isContactItem: true,
      to: '/preguntas-frecuentes'
   },
   {
      text: 'forvetorg',
      icon: IconBrandInstagram,
      isContactItem: false,
      to: 'https://www.instagram.com/forvetorg/',
      target:'_blank'
   },
   {
      text: 'forvetnovedades',
      icon: IconBrandFacebook,
      isContactItem: false,
      to: 'https://www.facebook.com/forvetnovedades/',
      target:'_blank'
   },
]