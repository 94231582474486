import {useEffect} from 'react';
import TagManager from "react-gtm-module";

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
};

function GoogleTagManager() {

    useEffect(() => {
        TagManager.initialize(tagManagerArgs);
    }, []);

    return null;
}

export default GoogleTagManager;