import NavLink from '../NavLink/NavLink'
import MegaMenuContainer from '../MegaMenu/MegaMenuContainer/MegaMenuContainer'
import { Button } from '@mantine/core'
import MegaMenuSection from '../MegaMenu/MegaMenuSection/MegaMenuSection'
import { useHeaderContext } from '../../../context/Header/HeaderContext'
import { useAppContext } from '../../../context/AppState/AppContext'
import { Link } from 'react-router-dom'
import useEliteStatus from '../../../hooks/useEliteStatus'

export default function HeaderNavList() {
   const { isMobile } = useAppContext()
   const { toggleBurger, onMouseEnterMenu, onMouseLeaveMenu, onClickMegaMenu } = useHeaderContext()
   const isElite = useEliteStatus()

   return (
      <ul className={`navbar-navlinks-container ${toggleBurger ? 'navbar-toggle-active' : 'navbar-toggle-inactive'}`}>
         < NavLink text='Inicio' to='/' />

         {/*< NavLink text='Blog' to='/blog' />*/}

         < NavLink text='Institucional' to='/institucional' />

          <NavLink text='Oferta académica' to='/cursos' />
         {/*{isMobile || isElite*/}
         {/*   ? <NavLink text='Oferta académica' to='/cursos' />*/}
         {/*   : <NavLink*/}
         {/*      text='Oferta académica'*/}
         {/*      onMouseEnter={onMouseEnterMenu}*/}
         {/*      onMouseLeave={onMouseLeaveMenu}*/}
         {/*      onClick={onClickMegaMenu}*/}
         {/*      withMenu*/}
         {/*   >*/}
         {/*      <MegaMenuContainer  >*/}
         {/*         < MegaMenuSection />*/}
         {/*      </MegaMenuContainer>*/}
         {/*   </NavLink>*/}
         {/*}*/}


         < NavLink text='Contacto' to='/contacto' />

         {isMobile && < NavLink text='Oferta Elite' to='/oferta-elite' />}

         <Button component={Link} to='/campus' fw='normal' color='var(--color-tertiary)' style={{ display: !isMobile ? 'none' : '' }}>
            CAMPUS
         </Button>
      </ul>
   )
}
