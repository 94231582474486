import './GlassCard.css'
import { openUrl } from '../../utils/openUrl'
import { useNavigate } from 'react-router-dom';

export default function GlassCard({ item }) {
   const navigate = useNavigate();

   const handleButtonClick = (item) => {
      if (item?.comercialInformation?.outsideCampus) {
         openUrl(item?.comercialInformation?.outsideCampusUrl)
      } else {
         navigate(`/especializaciones/${item._id}`)
      }
   }



   return (
      <article onClick={() => handleButtonClick(item)} class="GlassCard-main-container" >
         <div className='GlassCard-wrapper-container' >
            <img className="GlassCard--image" src={item?.photo} alt={item?.name} />

            <div className="GlassCard-info-wrapper">
               <h4 className="GlassCard-title">{item?.name?.toUpperCase()}</h4>
            </div>

            {/* <p>{item?.description}</p> */}

         </div>
      </article>
   )
}
