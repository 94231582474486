import React, {useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {Modal, Button, SimpleGrid, ScrollArea} from "@mantine/core";
import "./CourseCardModal.css";
import axios from "axios";
import {useIsMount} from "../../../../hooks/useIsMount";
import { openUrl } from "../../../../utils/openUrl";
import {useAppContext} from "../../../../context/AppState/AppContext";

const CourseCardModal = () => {
    const [opened, {open, close}] = useDisclosure(false);
    const [benefitsInfo, setbenefitsInfo] = useState([]);
    const isMount = useIsMount();
    const { isMobile } = useAppContext();

    const handleModalClose = () => {
        close();
    };
    const handleCardClick = (index) => {
        const updatedBenefits = [...benefitsInfo];
        updatedBenefits[index].clicked = !updatedBenefits[index].clicked;
        setbenefitsInfo(updatedBenefits);
    };

    const fetchBenefits = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_SERVER}/info/benefits`
            );
            const formattedResponse = response.data.map((benefit) => {
                return {
                    name: benefit.name,
                    image: benefit.image,
                    id: benefit._id,
                    benefit: benefit.benefit,
                    benefitUrl: benefit.redirectUrl,
                };
            });
            setbenefitsInfo(formattedResponse);
        } catch (error) {
            console.error("Error fetching testimonials data:", error);
        }
    };

    useEffect(() => {
        //TODO: Review, this fetch is called on first render
        if (!isMount) {
            fetchBenefits();
        }
    }, [isMount]);
    return (
        <div className="courseCardModal-container">
            <Modal
                classNames={{
                    content: "courseCardModal-root-modal",
                    title: "courseCardModal-title-modal",
                    header: "courseCardModal-header-modal",
                }}
                opened={opened}
                onClose={handleModalClose}
                title="¡Haz click en el logo de la institución para obtener la información del descuento!"
                size={isMobile ? "95%" : "70%"}
                scrollAreaComponent={ScrollArea.Autosize}
            >
                <div>
                    <SimpleGrid
                        cols={{base: 1, sm: 2, lg: 3}}
                        spacing={{base: 10, sm: "xs"}}
                        verticalSpacing={{base: "md", sm: "md"}}
                    >
                        {benefitsInfo.map((item, index) => (
                            <div
                                key={index}
                                className={`courseCardModal-firstGrid-element-card ${
                                    item.clicked ? "clicked" : ""
                                }`}
                                onClick={() => handleCardClick(index)}
                            >
                                <div className="courseCardModal-firstGrid-element-content">
                                    <div className="courseCardModal-firstGrid-element-front">
                                        <img
                                            className="courseCardModal-firstGrid-image"
                                            src={item.image}
                                            alt={item.name}
                                        />
                                    </div>
                                    <div className="courseCardModal-firstGrid-element-back">
                                        <div className="courseCardModal-back-content">
                                        <p className="courseCardModal-text-content">{item.benefit}</p>
                                        <Button onClick={() => openUrl(`${item.benefitUrl}`, true)}> Obtener beneficio </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </SimpleGrid>
                </div>
            </Modal>

            <Button fw="normal"
                    variant="default"
                    onClick={open} fullWidth>
                Consultar beneficios
            </Button>
        </div>
    );
};

export default CourseCardModal;
