import {Button, Stack, TextInput} from '@mantine/core'
import {IconSearch, IconX} from '@tabler/icons-react'
import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'
import useWindowDimensions from '../../hooks/useWindowDimensions'


function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export default function SearchBanner({btnText = 'EXPLORAR', height = 50, maxWidth = 400}) {
    const query = useQuery()
    const [searchQuery, setSearchQuery] = useState(query.get('query') || '')
    const [typingTimeout, setTypingTimeout] = useState(null);


    const {width} = useWindowDimensions()
    const isMobile = width <= 768

    const handleSearch = (event) => {
        event.preventDefault()
        if (searchQuery) {
            return window.location.href = `/cursos?query=${encodeURIComponent(searchQuery)}`
        }
        window.location.href = `/cursos`
    }

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(setTimeout(() => {
            handleSearch(event)
        }, 3000));
    };


    return (
        <form style={{width: '100%'}} onSubmit={(event) => handleSearch(event)}>
            <Stack align='center' w='100%' maw={maxWidth} m='auto' p={0}>
                <TextInput
                    leftSection={<IconSearch/>}
                    rightSection={
                        btnText && !isMobile ? <Button
                                variant="filled"
                                style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                bg="var(--color-tertiary)"
                                fw="normal"
                                h={height}
                                type={'submit'}
                            >
                                {btnText}
                            </Button>
                            : searchQuery && <IconX
                            onClick={() => {
                                setSearchQuery('')
                                window.location.href = `/cursos`
                            }}
                            style={{cursor: 'pointer'}}
                        />
                    }
                    w='100%'
                    styles={{
                        input: {
                            height,
                            backgroundColor: 'transparent',
                            color: 'var(--color-text-primary)'
                        }
                    }}
                    placeholder='Encontrá tu curso'
                    value={searchQuery}
                    onChange={(event) => handleInputChange(event)}
                />
                {(isMobile && btnText) && <Button
                    variant="filled"
                    bg="var(--color-tertiary)"
                    fw="normal"
                    h={height}
                    type={'submit'}
                >
                    {btnText}
                </Button>}

            </Stack>
        </form>
    )
}
